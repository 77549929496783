import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ProtectedIcon from '../../assets/icons/listing-trust/protection.png';
import ReturnsIcon from '../../assets/icons/listing-trust/returns.png';
import SupportIcon from '../../assets/icons/listing-trust/support.png';

export const ListingTrustSection = () => {
  const intl = useIntl();

  const items = [
    {
      title: intl.formatMessage({ id: 'listing_page.trust_section.secure_payments_title' }),
      description: intl.formatMessage({ id: 'listing_page.trust_section.secure_payments_description' }),
      icon: ProtectedIcon,
    },
    {
      title: intl.formatMessage({ id: 'listing_page.trust_section.customer_support_title' }),
      description: intl.formatMessage({ id: 'listing_page.trust_section.customer_support_description' }),
      icon: SupportIcon,
    },
    {
      title: intl.formatMessage({ id: 'listing_page.trust_section.free_returns_title' }),
      description: intl.formatMessage({ id: 'listing_page.trust_section.free_returns_description' }),
      icon: ReturnsIcon,
    },
  ];

  return (
    <Box
      mx="auto"
      width="100%"
      sx={{
        maxWidth: 1320,
        '@media (max-width: 1439px)': { maxWidth: '100vw', px: 3 },
        '@media (max-width: 767px)': { maxWidth: '100vw', px: 2 },
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        gap={{ xs: 0, md: 2 }}
        py={{ xs: 0, md: 3 }}
        mt={{ xs: 4, md: 3 }}
        bgcolor={{ xs: 'transparent', md: '#FAFAFA' }}
        border={{ xs: 0, md: 1 }}
        borderColor={{ xs: 'transparent', md: '#E9ECEF' }}
        borderRadius={{ xs: 0, md: '12px' }}
      >
        {items.map((item, index) => (
          <Stack key={index} direction="row" alignItems="center" gap={2} py={{ xs: 1, md: 2 }} px={{ xs: 0, md: 3 }}>
            <Box component="img" src={item.icon} alt={item.title} width={32} height={32} />
            <Stack direction="column" gap={1}>
              <Typography variant="h2" color="#1A1A1A" fontSize={16} fontWeight={500}>
                {item.title}
              </Typography>
              <Typography variant="body1" color="#495057" fontSize={14} fontWeight={400}>
                {item.description}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
