import React from 'react';
import Linkify from 'react-linkify';
import { compose } from 'redux';
import css from '../ListingPage.module.css';

import Collapsible from '../../../components/Collapsible/Collapsible';
import { injectIntl } from '../../../util/reactIntl';

import ProtectionIcon from './protection.svg';
import SaveIcon from './save.svg';
import ShippingIcon from './shipping.svg';

function SectionShoppingDetails(props) {
  const { intl } = props;

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  const renderShoppingDetailsSection = (type, image) => {
    return (
      <div className={css.shoppingDetailsInfoWrapper}>
        {/* <img className={css.shoppingDetailsInfoImage} src={image}/> */}
        <div className={css.shoppingDetailsInfoTextWrapper}>
          <p className={css.shoppingDetailsTitle}>
            {intl.formatMessage({ id: `listing_page.shopping.${type}.title` })}
          </p>
          <p className={css.shoppingDetailsDescription}>
            {intl.formatMessage({ id: `listing_page.shopping.${type}.description` })}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={css.sectionShoppingDetails}>
      <Collapsible openByDefault={false} label={intl.formatMessage({ id: 'listing_page.shopping.label' })}>
        <Linkify componentDecorator={componentDecorator}>
          <div className={css.sectionShoppingDetailsWrapper}>
            {renderShoppingDetailsSection('buy_confidently', ProtectionIcon)}
            {renderShoppingDetailsSection('confirm_item', ShippingIcon)}
            {renderShoppingDetailsSection('release_money', SaveIcon)}
          </div>
        </Linkify>
      </Collapsible>
    </div>
  );
}

export default compose(injectIntl)(SectionShoppingDetails);
